export const URL_TRUSTPILOT_SCRIPT =
  '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const DEFAULT_LOCALE = 'en-GB';

export const DEFAULT_LOCALE_BUSINESS_ID = process.env.NEXT_PUBLIC_TRUSTPILOT_ID;

export const STARS = '1,2,3,4,5';

export const TEMPLATES = {
  CAROUSEL: 'CAROUSEL',
  HORIZONTAL: 'HORIZONTAL',
};

export const REVIEW_LANGUAGES = {
  ALL: 'All languages',
  SAME_AS_PAGE: 'Same language as the page',
};

export const AVAILABLE_LANGUAGES = [
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'hr',
  'hu',
  'it',
  'lt',
  'lv',
  'nb',
  'nl',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'sv',
];

export const TEMPLATE_CONFIGS = {
  [TEMPLATES.CAROUSEL]: {
    width: '100%',
    height: '160px',
    mobileHeight: '320px',
    theme: THEMES.LIGHT,
    id: '53aa8912dec7e10d38f59f36',
  },
  [TEMPLATES.HORIZONTAL]: {
    width: '100%',
    height: '28px',
    mobileHeight: '28px',
    theme: THEMES.LIGHT,
    id: '5406e65db0d04a09e042d5fc',
  },
};
